
import { Component, Vue } from 'vue-property-decorator'
import TheDownload from '@/components/download/TheDownload.vue'

@Component({
  components: {
    TheDownload
  },
  metaInfo() {
    return{
    title: this.$tc('caption'),
  }}
})

export default class DownloadPage extends Vue {

}
