
import "@/assets/styles/imports/download.sass"
import { Component, Vue } from 'vue-property-decorator'
import api from '@/api'

@Component
export default class TheDownload extends Vue {
  token = ''
  empty = false

  getToken () {
    this.token = this.$route.params.token
  }

  async downloadFile () {
    this.getToken()
    if (!this.token) return this.empty = true
    await api.firmware.downloadFile(this.token)
  }

  mounted () {
    this.downloadFile()
  }
}
